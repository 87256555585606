/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* EXAMPLES
<Alert variant="filled" severity="success">
  This is a filled success Alert.
</Alert>
<Alert variant="filled" severity="info">
  This is a filled info Alert.
</Alert>
<Alert variant="filled" severity="warning">
  This is a filled warning Alert.
</Alert>
*/


import Alert from "@mui/material/Alert"
import MKAlert from "components/MKAlert";
import MKTypography from "components/MKTypography";

function SiteAlerts() {
  return (
    <>
      {/* <Alert severity="info">
        Momenteel is het vakantie. Lessen starten weer op Maandag 26-08. <i>(Vrij trainen kan natuurlijk zoals altijd in de groepsapp besproken worden)</i>
      </Alert> <br /> */}
      <MKAlert variant="filled" color="warning" dismissible>
        <p>
        Let Op het nieuwe rooster voor de locatie Sliedrecht.
        </p>
      </MKAlert>
    </>
  );
}

export default SiteAlerts;
