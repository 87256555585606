/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import judo from "assets/images/Judo_Small.jpg";
import karate from "assets/images/Karate_Small.jpg";
import aikibudo from "assets/images/Aikibudo_Small.jpg";
import bjj from "assets/images/BJJ_Small.jpg";
import daito from "assets/images/Daito_Small.jpg";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import N2024_10_01_openDag from "./news/2024-10-01_opendag"
import N2024_10_01_Rooster from "./news/2024-10-01_rooster";
import N2024_10_14_OpenDag from "./news/2024-10-14_opendag";


function NewsTiles() {
  return (
    <MKBox
      bgColor="white"
      component="section"
      color="dark"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h2" color="dark">
              Nieuwsberichten
            </MKTypography>
            <MKTypography variant="body2" color="dark" opacity={0.8}>
              Hieronder zijn alle eerdere nieuwsberichten terug te vinden.
            </MKTypography>
          </Grid>
        </Grid>


      <Grid container spacing={5}>
        <N2024_10_14_OpenDag />
        <N2024_10_01_Rooster />
        <N2024_10_01_openDag />
      </Grid>

      </Container>
    </MKBox>
  );
}

export default NewsTiles;
