/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import judo from "assets/images/Judo_Small.jpg";
import karate from "assets/images/Karate_Small.jpg";
import aikibudo from "assets/images/Aikibudo_Small.jpg";
import bjj from "assets/images/BJJ_Small.jpg";
import daito from "assets/images/Daito_Small.jpg";


function Locations() {
  return (
    <MKBox
      bgColor="white"
      component="section"
      color="dark"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h2" color="dark">
              Locaties
            </MKTypography>
            <MKTypography variant="body2" color="dark" opacity={0.8}>
              Wij geven les op de volgende locaties
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <MKTypography variant="h4" color="dark" opacity={0.8}>Sliedrecht</MKTypography>
            Budocentrum<br />
            <i>Professor van Musschenbroekstraat 4, 3362SW, Sliedrecht</i><br />
            <a href="https://www.google.com/maps/place/Professor+van+Musschenbroekstraat+4,+3362+SW+Sliedrecht/@51.8206002,4.7790208,17z/data=!3m1!4b1!4m6!3m5!1s0x47c6820cee10419d:0xa2e5b4e5ffef0d14!8m2!3d51.8206002!4d4.7815957!16s%2Fg%2F11h044qnkr?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D" target="_blank"><MKTypography variant="body1" color="info" opacity={0.8}>Open op kaart</MKTypography></a>
          </Grid>
          <Grid item xs={12} lg={4}>
            <MKTypography variant="h4" color="dark" opacity={0.8}>Nieuw-Lekkerland</MKTypography>
            Het Carillon Dorpshuis<br />
            <i>Raadhuisplein 1, 2957RK, Nieuw-Lekkerland</i><br />
            <a href="https://www.google.com/maps/place/Het+Carillon+Dorpshuis/@51.8870738,4.6778443,17z/data=!3m1!4b1!4m5!3m4!1s0x47c42b50fca21ddb:0xe81a7ddaf99e7b75!8m2!3d51.8871235!4d4.6824718" target="_blank"><MKTypography variant="body1" color="info" opacity={0.8}>Open op kaart</MKTypography></a>
            <br />
            Sporthal De Klipper<br />
            <i>Klipperstraat 20, 2957EM, Nieuw-Lekkerland</i><br />
            <a href="https://www.google.com/maps/place/Sporthal+De+Klipper/@51.8841786,4.6628637,935m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47c42c62d881764b:0xbd1440f7cb7e1924!8m2!3d51.8841786!4d4.6654386!16s%2Fg%2F1td8y8rh?entry=ttu" target="_blank"><MKTypography variant="body1" color="info" opacity={0.8}>Open op kaart</MKTypography></a>
          </Grid>
          <Grid item xs={12} lg={4}>
            <MKTypography variant="h4" color="dark" opacity={0.8}>Streefkerk</MKTypography>
            Optisport Gymzaal Streefkerk.<br />
            <i>Molenakker 10, 2959AS, Streefkerk</i><br />
            <a href="https://www.google.com/maps/place/Optisport+Gymzaal+Streefkerk/@51.9002012,4.7436333,17z/data=!3m1!4b1!4m5!3m4!1s0x47c42ae672b3d605:0xbe863c455292d8b3!8m2!3d51.9001979!4d4.745822" target="_blank"><MKTypography variant="body1" color="info" opacity={0.8}>Open op kaart</MKTypography></a>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Locations;
